import React, { useRef } from "react";

import emailjs from "@emailjs/browser";

import logo from "../logo.svg";
import pic1 from "../pics/pic1.png";
import pic2 from "../pics/pic2.png";
import "./homescreen.css";

function Homescreen() {
    React.useEffect(() => {
        function handleResize() {}

        window.addEventListener("resize", handleResize);
    });
    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const togglePanel = () => {
        setIsCollapsed(!isCollapsed);
    };
    const [isCollapsed2, setIsCollapsed2] = React.useState(false);
    const togglePanel2 = () => {
        setIsCollapsed2(!isCollapsed2);
    };
    const [isCollapsed3, setIsCollapsed3] = React.useState(false);
    const togglePanel3 = () => {
        setIsCollapsed3(!isCollapsed3);
    };

    const form = useRef();
    const content1Ref = useRef();
    const content2Ref = useRef();
    const content3Ref = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        setconfirmation("pending");
        emailjs
            .sendForm(
                "service_vz8jmsn",
                "template_0146zdi",
                form.current,
                "b2b0ANrsBiu5Gho2u"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setconfirmation("true");
                    handleConfirmation();
                },
                (error) => {
                    console.log(error.text);
                    setconfirmation("false");
                }
            );
    };
    const handleConfirmation = () => {
        setname("");
        setemail("");
        settype("DJ");
        setcomment("");
        setdate("");
        settime("");
        settime2("");
    };
    const [name, setname] = React.useState("");
    const [email, setemail] = React.useState("");
    const [type, settype] = React.useState("DJ");
    const [comment, setcomment] = React.useState("");
    const [date, setdate] = React.useState("");
    const [time, settime] = React.useState("");
    const [time2, settime2] = React.useState("");
    const [confirmation, setconfirmation] = React.useState("");

    const handlenameChange = (event) => {
        setname(event.target.value);
    };
    const handleemailChange = (event) => {
        setemail(event.target.value);
    };
    const handletypeChange = (event) => {
        settype(event.target.value);
    };
    const handlecommentChange = (event) => {
        setcomment(event.target.value);
    };
    const handledateChange = (event) => {
        setdate(event.target.value);
    };
    const handletimeChange = (event) => {
        settime(event.target.value);
    };
    const handletime2Change = (event) => {
        settime2(event.target.value);
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="filt-logo" alt="logo" />
            </header>
            <div className="body">
                <div className="referencesTab tab">
                    <div className="innertab">
                        <div onClick={togglePanel3} className="tabText">
                            References
                        </div>
                        <div
                            className={
                                isCollapsed3
                                    ? "infoPanel content_open referencesContentOpen"
                                    : "infoPanel content_closed"
                            }
                            ref={content1Ref}
                            style={
                                isCollapsed3
                                    ? {
                                          height:
                                              content1Ref.current.scrollHeight +
                                              "px",
                                          transition: "height ease 0.9s",
                                      }
                                    : {
                                          transition: "height ease 0.9s",
                                          height: "0px",
                                      }
                                // { height: "100px" }
                            }
                            id="expand-container"
                        >
                            <p className="infoText">
                                Barvista, Vesper, Dulci,...
                            </p>
                            <p>
                                For more info or a demo track, please contact:{" "}
                                <a href="mailto:info@fil-t.com">
                                    info@fil-t.com
                                </a>
                            </p>
                            <img src={pic1} className="pic" alt="pic1" />
                            <img src={pic2} className="pic" alt="pic2" />
                        </div>
                    </div>
                </div>
                <div className="bookingsTab tab">
                    <div className="innertab">
                        <div onClick={togglePanel} className="tabText">
                            Bookings
                        </div>
                        <div
                            className={
                                isCollapsed
                                    ? "infoPanel content_open bookingsContentOpen"
                                    : "infoPanel content_closed"
                            }
                            ref={content2Ref}
                            style={
                                isCollapsed
                                    ? {
                                          height:
                                              content2Ref.current.scrollHeight +
                                              "px",
                                          transition: "height ease 0.9s",
                                      }
                                    : {
                                          transition: "height ease 0.9s",
                                          height: "0px",
                                      }
                            }
                            id="expand-container"
                        >
                            <p className="infoText">
                                Interested? Please fill in the form below and I
                                will contact you as soon as possible.
                            </p>
                            <form
                                ref={form}
                                className="form"
                                onSubmit={handleSubmit}
                            >
                                <div className="formItem withlabel">
                                    <label>Name:</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={handlenameChange}
                                    />
                                </div>
                                <div className="formItem withlabel">
                                    <label>Email:</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={email}
                                        onChange={handleemailChange}
                                    />
                                </div>
                                <div className="formItem withlabel">
                                    <label>Type:</label>
                                    <select
                                        name="type"
                                        value={type}
                                        onChange={handletypeChange}
                                    >
                                        <option value="DJ">DJ-set</option>
                                    </select>
                                </div>
                                {type == "DJ" ? (
                                    <>
                                        <div className="formItem withlabel">
                                            <label>Date:</label>
                                            <input
                                                type="date"
                                                name="date"
                                                value={date}
                                                onChange={handledateChange}
                                            />
                                        </div>
                                        <div className="formItem withlabel">
                                            <label>Starttime:</label>
                                            <input
                                                type="time"
                                                name="time"
                                                value={time}
                                                onChange={handletimeChange}
                                            />
                                            <label>Endtime:</label>
                                            <input
                                                type="time"
                                                name="time2"
                                                value={time2}
                                                onChange={handletime2Change}
                                            />
                                        </div>
                                    </>
                                ) : null}
                                <div className="formItem withlabel">
                                    <label>Comments:</label>
                                    <input
                                        type="text"
                                        name="comment"
                                        value={comment}
                                        onChange={handlecommentChange}
                                    />
                                </div>
                                <div className="formItem submit">
                                    <input type="submit" value="Submit" />
                                </div>
                                {confirmation == "pending" ? (
                                    <div className="formItem confirmation">
                                        <>Please wait...</>
                                    </div>
                                ) : null}
                                {confirmation == "true" ? (
                                    <div className="formItem confirmation good">
                                        <>Your request was sent succesfully!</>
                                    </div>
                                ) : null}
                                {confirmation == "false" ? (
                                    <div className="formItem confirmation bad">
                                        <>Please retry</>
                                    </div>
                                ) : null}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="contactTab tab">
                    <div className="innertab">
                        <div onClick={togglePanel2} className="tabText">
                            Contact
                        </div>
                        <div
                            className={
                                isCollapsed2
                                    ? "infoPanel content_open contactContentOpen"
                                    : "infoPanel content_closed"
                            }
                            ref={content3Ref}
                            style={
                                isCollapsed2
                                    ? {
                                          height:
                                              content3Ref.current.scrollHeight +
                                              "px",
                                          transition: "height ease 0.9s",
                                      }
                                    : {
                                          transition: "height ease 0.9s",
                                          height: "0px",
                                      }
                            }
                            id="expand-container"
                        >
                            <p className="infoText">
                                Instagram:{" "}
                                <a href="https://www.instagram.com/invites/contact/?i=i4lpwr2zbpvu&utm_content=ppz329p">
                                    @djfil_t
                                </a>
                            </p>
                            <p className="infoText">
                                For other questions, different from a DJ-set or
                                booking-request, please reach out to:{" "}
                                <a href="mailto:contact@fil-t.com">
                                    contact@fil-t.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="tab spacer"></div>
            </div>
            <div className="bottomBar">
                <a className="fininfo">Fil Daems </a>
                <a className="fininfo">ondernemingsnummer: 0792.460.009</a>
            </div>
        </div>
    );
}

export default Homescreen;
