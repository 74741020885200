import React, { useRef } from "react";

import "./App.css";
import Homescreen from "./pages/homescreen.js";
import Equipment from "./pages/equipment.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
    React.useEffect(() => {});

    return (
        <Router>
            <Routes>
                <Route exact path="/" exact element={<Homescreen />} />
                <Route path="/equipment" element={<Equipment />} />
            </Routes>
        </Router>
    );
}

export default App;
