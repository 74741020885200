import React, { useRef } from "react";
import { Link } from "react-router-dom";

import emailjs from "@emailjs/browser";

import logo from "../logo.svg";
import pic1 from "../pics/pic1.png";
import pic2 from "../pics/pic2.png";
import "./equipment.css";

function Equipment() {
    const [isCollapsed2, setIsCollapsed2] = React.useState(false);
    const togglePanel2 = () => {
        setIsCollapsed2(!isCollapsed2);
    };
    const [isCollapsed3, setIsCollapsed3] = React.useState(false);
    const togglePanel3 = () => {
        setIsCollapsed3(!isCollapsed3);
    };
    const content1Ref = useRef();
    const content3Ref = useRef();

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="filt-logo" alt="logo" />
            </header>
            <div className="body">
                <div className="referencesTab tab">
                    <div className="innertab">
                        <div onClick={togglePanel3} className="tabText">
                            Equipment
                        </div>
                        <div
                            className={
                                isCollapsed3
                                    ? "infoPanel content_open referencesContentOpen"
                                    : "infoPanel content_closed"
                            }
                            ref={content1Ref}
                            style={
                                isCollapsed3
                                    ? {
                                          height:
                                              content1Ref.current.scrollHeight +
                                              "px",
                                          transition: "height ease 0.9s",
                                      }
                                    : {
                                          transition: "height ease 0.9s",
                                          height: "0px",
                                      }
                                // { height: "100px" }
                            }
                            id="expand-container"
                        >
                            <p className="infoText">
                                DJ-equipment, if provided by the organizer or
                                venue, should be one of the combinations
                                mentioned below. If equipment is expected to be
                                brought by Fil-T himself, a rental-fee will be
                                charged.
                            </p>
                            <p className="infoText">DJ-desk consists of:</p>
                            <div className="summing-div">
                                <text className="summing-up-list">
                                    1 x Pioneer Mixer (DJM)
                                </text>
                                <text className="summing-up">DJM-A9</text>
                                <text className="summing-up">DJM-V10</text>
                                <text className="summing-up">DJM-900NXS2</text>
                                <text className="summing-up">DJM-750MK2</text>
                                <text className="summing-up">DJM-900NXS</text>
                                <text className="summing-up-list">
                                    2+ x Pioneer Players (CDJ/XDJ)
                                </text>
                                <text className="summing-up">CDJ-3000</text>
                                <text className="summing-up">CDJ-2000NXS2</text>
                                <text className="summing-up">CDJ-2000NXS</text>
                                <text className="summing-up">XDJ-1000MK2</text>
                                <p className="infoText">
                                    All-in-0ne, stand-alone, DJ-systems from
                                    Pioneer also would suffice
                                </p>
                                <text className="summing-up">XDJ-RX3</text>
                                <text className="summing-up">XDJ-XZ</text>
                            </div>
                            <p>
                                For more info or an other agreement, please
                                contact:{" "}
                                <a href="mailto:info@fil-t.com">
                                    info@fil-t.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="contactTab tab">
                    <div className="innertab">
                        <div onClick={togglePanel2} className="tabText">
                            Press-kit
                        </div>
                        <div
                            className={
                                isCollapsed2
                                    ? "infoPanel content_open contactContentOpen"
                                    : "infoPanel content_closed"
                            }
                            ref={content3Ref}
                            style={
                                isCollapsed2
                                    ? {
                                          height:
                                              content3Ref.current.scrollHeight +
                                              "px",
                                          transition: "height ease 0.9s",
                                      }
                                    : {
                                          transition: "height ease 0.9s",
                                          height: "0px",
                                      }
                            }
                            id="expand-container"
                        >
                            <p>
                                Press-kit's can be used freely on social-media
                                and for other advertising purposes.
                            </p>
                            <Link
                                to="/presskits/pk_small_DJFil-T.zip"
                                target="_blank"
                                download
                            >
                                Download Small PressKit
                            </Link>
                            <p className="infoText">
                                More press-kit's coming soon.
                            </p>
                            <p>
                                For more info, please contact:{" "}
                                <a href="mailto:info@fil-t.com">
                                    info@fil-t.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottomBar">
                <a className="fininfo">Fil Daems </a>
                <a className="fininfo">ondernemingsnummer: 0792.460.009</a>
            </div>
        </div>
    );
}

export default Equipment;
